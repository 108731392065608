<template>
    <footer class="bg-[#136d66] text-white footer">
        <div class="container mx-auto px-4 py-12">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                <!-- Logo and contact info -->
                <div class="space-y-4">
                    <img src="@/assets/img/rgob.png" alt="Invest Bhutan Logo" class="h-28 w-auto mb-4" />
                    <h3 class="text-2xl font-bold">Invest Bhutan</h3>
                    <div class="space-y-2">
                        <p class="flex items-center">
                            <Mail class="mr-2 h-5 w-5" /> {{ contactInfo.email }}
                        </p>
                        <p class="flex items-center">
                            <Phone class="mr-2 h-5 w-5" /> {{ contactInfo.phone }}
                        </p>
                        <p class="flex items-center">
                            <MapPin class="mr-2 h-5 w-5" /> {{ contactInfo.address }}
                        </p>
                    </div>
                </div>

                <!-- Quick Links -->
                <div>
                    <h4 class="text-xl font-semibold mb-4">Quick Links</h4>
                    <ul class="space-y-2">
                        <li v-for="link in quickLinks" :key="link.name">
                            <a :href="link.url"
                                class="hover:text-green-200 transition-colors duration-300 flex items-center">
                                <ChevronsRight class="mr-2 h-4 w-4" /> {{ link.name }}
                            </a>
                        </li>
                    </ul>
                </div>

                <!-- Popular Links -->
                <div>
                    <h4 class="text-xl font-semibold mb-4">Popular Links</h4>
                    <ul class="space-y-2">
                        <li v-for="link in popularLinks" :key="link.name">
                            <a :href="link.url" target="_blank" rel="noopener noreferrer"
                                class="hover:text-green-200 transition-colors duration-300 flex items-center">
                                <ExternalLink class="mr-2 h-4 w-4" /> {{ link.name }}
                            </a>
                        </li>
                    </ul>
                </div>

                <!-- Newsletter and Social Links -->
                <div>
                    <h4 class="text-xl font-semibold mb-4">Stay Connected</h4>
                    <p class="mb-4"> Follow us to stay up-to-date with our latest news and events.</p>
                    <div class="flex space-x-4">
                        <a href="https://facebook.com/investbhutan"
                            class="text-white hover:text-green-200 transition-colors duration-300" target="_blank"
                            rel="noopener noreferrer">
                            <Facebook class="h-6 w-6" />
                        </a>
                        <a href="https://www.linkedin.com/company/invest-bhutan-department-of-industry/"
                            class="text-white hover:text-green-200 transition-colors duration-300" target="_blank"
                            rel="noopener noreferrer">
                            <Linkedin class="h-6 w-6" />
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Copyright area and Visitor Counter -->
        <div class="bg-[#0d4c47] py-6">
            <div class="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center">
                <div>
                    <p>&copy; {{ currentYear }} Invest Bhutan. All rights reserved.</p>
                    <p class="text-[8px] text-transparent non-selectable">Designed by Ujwal Hollica Pradhan. Call
                        +975-17795088</p>
                </div>

                <!-- <div class="flex items-center mt-4 md:mt-0 space-x-6">
                    <div class="flex items-center">
                        <Users class="h-5 w-5 mr-2" />
                        <span>Total Visitors: {{ totalVisitorCount }}</span>
                    </div>
                </div> -->
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import api from '@/services/api.js'
import { Mail, Phone, MapPin, ChevronsRight, ExternalLink, Facebook, Linkedin, Users } from 'lucide-vue-next'

const contactInfo = ref({
    email: 'fdiinquiry@moice.gov.bt',
    phone: '+975-2-330435 / 337623 / 337624',
    address: 'Department of Industry, MOICE',
})

const quickLinks = ref([
    { name: 'About Us', url: '/about' },
    { name: 'Service Provider', url: '/service-provider' },
    { name: 'FAQs', url: '/faqs' },
    { name: 'Login', url: '/login' },
    { name: 'Register', url: '/register' },
])

const popularLinks = ref([
    { name: 'G2C Service', url: 'https://www.citizenservices.gov.bt/' },
    { name: 'Integrated Business Licensing System', url: 'https://ibls.moea.gov.bt/' },
    { name: 'Ministry of Industry, Commerce and Employment', url: 'https://www.moice.gov.bt/' },
    { name: 'Industry Information System', url: 'https://iis.industry.gov.bt/' },
    { name: 'Department of Industry', url: 'https://industry.gov.bt/' },
])

const currentYear = new Date().getFullYear()

onMounted(async () => {
  await trackVisitor()  // Track visitor and increment counts
})

async function trackVisitor() {
  try {
    const payload = {
      ip_address: await getIpAddress(),
      session_id: getSessionId(),
      user_agent: navigator.userAgent,
      referrer: document.referrer
    }
    
    const response = await api.post('webcontents/api/visitor-statistics-summary/', payload)
    console.log('Visitor tracked:', response.data)
  } catch (error) {
    console.error('Error tracking visitor:', error)
  }
}


async function getIpAddress() {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error('Error fetching IP:', error);
    return '0.0.0.0';  // Default value if IP fetch fails
  }
}

function getSessionId() {
  let sessionId = localStorage.getItem('session_id');
  if (!sessionId) {
    sessionId = Math.random().toString(36).substring(2);
    localStorage.setItem('session_id', sessionId);
  }
  return sessionId;
}
</script>

<style scoped>
.footer {
    background-image: url('@/assets/img/footer.png');
    background-size: cover;
    background-position: center;
    background-blend-mode: overlay;
}

.non-selectable {
    user-select: none;
    /* Disables text selection */
    pointer-events: none;
    /* Disables any pointer events, making the text non-clickable */
}
</style>