<!-- @/components/ChatComponent.vue -->
<script setup lang="ts">
import { ref, onMounted, nextTick } from 'vue'
import botIcon from "@/assets/img/bot.png"
import userIcon from "@/assets/img/user.png"


const isOpen = ref(false)
const messages = ref([])
const newMessage = ref('')
const chatContainer = ref(null)
const isTyping = ref(false)

const toggleChat = () => {
  isOpen.value = !isOpen.value
  if (isOpen.value) {
    nextTick(() => {
      scrollToBottom()
    })
  }
}

const sendMessage = () => {
  if (newMessage.value.trim()) {
    messages.value.push({ id: Date.now(), text: newMessage.value, sender: 'user' })
    newMessage.value = ''
    scrollToBottom()
    simulateBotResponse()
  }
}

const simulateBotResponse = () => {
  isTyping.value = true
  scrollToBottom()
  setTimeout(() => {
    isTyping.value = false
    const responses = [
      'Thank you for your interest in investing in Bhutan. How can I assist you today?',
      'Bhutan offers unique investment opportunities. What sector are you most interested in?',
      "I'd be happy to provide more information about Bhutan's investment climate. What would you like to know?",
      'Investing in Bhutan can be a rewarding experience. Do you have any specific questions about the process?',
      "Bhutan's economy is growing steadily. Are you looking for short-term or long-term investment options?"
    ]
    const randomResponse = responses[Math.floor(Math.random() * responses.length)]
    messages.value.push({
      id: Date.now(),
      text: randomResponse,
      sender: 'bot'
    })
    scrollToBottom()
  }, 1500)
}

const scrollToBottom = () => {
  nextTick(() => {
    if (chatContainer.value) {
      chatContainer.value.scrollTop = chatContainer.value.scrollHeight
    }
  })
}

onMounted(() => {
  scrollToBottom()
})
</script>

<template>
  <div class="fixed bottom-4 right-4 z-50">
    <button
      @click="toggleChat"
      class="bg-gradient-to-r from-bhutan-green to-bhutan-green-light text-white rounded-full p-4 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:scale-105"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
        />
      </svg>
    </button>

    <div
      v-if="isOpen"
      class="absolute bottom-16 right-0 w-96 bg-white rounded-lg shadow-2xl overflow-hidden transition-all duration-300 transform origin-bottom-right"
    >
      <div
        class="bg-gradient-to-r from-bhutan-green to-bhutan-green-light text-white p-4 flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-8 w-8 mr-2"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
            clip-rule="evenodd"
          />
        </svg>
        <h3 class="text-lg font-semibold">Invest Bhutan</h3>
      </div>
      <div ref="chatContainer" class="h-96 overflow-y-auto p-4 space-y-4">
        <div v-if="messages.length === 0" class="text-center text-gray-500 mt-4">
          Welcome! How can we assist you with investing in Bhutan today?
        </div>
        <div
          v-for="message in messages"
          :key="message.id"
          :class="['flex', message.sender === 'user' ? 'justify-end' : 'justify-start']"
        >
          <div
            :class="[
              'max-w-3/4 p-3 rounded-lg shadow-md flex items-start space-x-2',
              message.sender === 'user'
                ? 'bg-gradient-to-r from-bhutan-green to-bhutan-green-light text-white'
                : 'bg-blue-50 text-gray-800'
            ]"
          >
            <img
              v-if="message.sender === 'bot'"
              :src="botIcon"
              alt="Bot"
              class="w-6 h-6 rounded-full"
            />
            <img v-else :src="userIcon" alt="User" class="w-6 h-6 rounded-full" />
            <p>{{ message.text }}</p>
          </div>
        </div>
        <div v-if="isTyping" class="flex justify-start">
          <div
            class="bg-blue-50 text-gray-800 p-3 rounded-lg shadow-md flex items-center space-x-2"
          >
            <img :src="botIcon" alt="Bot" class="w-6 h-6 rounded-full" />
            <div class="typing-indicator">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4 border-t">
        <form @submit.prevent="sendMessage" class="flex">
          <input
            v-model="newMessage"
            type="text"
            placeholder="Type your message..."
            class="flex-grow p-2 border rounded-l-lg focus:outline-none focus:ring-2 focus:ring-bhutan-green"
          />
          <button
            type="submit"
            class="bg-gradient-to-r from-bhutan-green to-bhutan-green-light hover:from-bhutan-green-light hover:to-bhutan-green text-white p-2 rounded-r-lg transition-all duration-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
              />
            </svg>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
.from-bhutan-green {
  --tw-gradient-from: #00676c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 108, 53, 0));
}

.to-bhutan-green-light {
  --tw-gradient-to: #00676c;
}

.typing-indicator {
  display: flex;
  align-items: center;
}

.typing-indicator span {
  height: 8px;
  width: 8px;
  background-color: #3b82f6;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
  animation: bounce 1.4s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) {
  animation-delay: -0.32s;
}

.typing-indicator span:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
</style>
