import './assets/main.css'
import '@fortawesome/fontawesome-free/css/all.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEye, faEdit, faTrash, faPlus, faProjectDiagram, faInbox } from '@fortawesome/free-solid-svg-icons'
import VueApexCharts from "vue3-apexcharts";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

const app = createApp(App)

library.add(faEye, faEdit, faTrash, faPlus, faProjectDiagram, faInbox)

app.use(createPinia())
app.use(router)
app.use(VueApexCharts)
app.use(Toast);

app.mount('#app')
