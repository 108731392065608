import { defineStore } from 'pinia'
import axios from 'axios'
import { useToast } from 'vue-toastification'

interface User {
  username: string
  user_type: string
  token: any
}

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null as User | null,
    token: null as string | null,
    role: null as string | null,
    userId: null as number | null, // Add userId to state
    intendedRoute: null as string | null,
    csrfToken: null as string | null, // Add csrfToken to state
  }),
  getters: {
    isLoggedIn: (state) => !!state.token,
    userName: (state) => state.user?.username || '',
    userRole: (state) => state.role || '',
  },
  actions: {
    setUser(user: User) {
      this.user = user;
      this.role = user.user_type;
      localStorage.setItem('userData', JSON.stringify(user));
      localStorage.setItem('userRole', user.user_type);
    },
    setUserId(userId: number) { // Add a method to set the userId
      this.userId = userId;
      localStorage.setItem('userId', userId.toString());
    },
    setToken(token: string) {
      this.token = token;
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Authorization'] = `Token ${token}`;
    },
    setCsrfToken(csrfToken: string) {
      this.csrfToken = csrfToken;
      localStorage.setItem('csrfToken', csrfToken);
      axios.defaults.headers.common['X-CSRFToken'] = csrfToken;
    },
    initializeStore() {
      const token = localStorage.getItem('token');
      const userData = localStorage.getItem('userData');
      const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
      const csrfToken = localStorage.getItem('csrfToken');
      if (token) {
        this.setToken(token);
      }
      if (userData) {
        const user = JSON.parse(userData);
        this.setUser(user);
      }
      if (userId) {
        this.setUserId(parseInt(userId));
      }
      if (csrfToken) {
        this.setCsrfToken(csrfToken);
      }
    },
    async logout() {
      const toast = useToast();
      try {
        // Clear user data
        this.user = null;
        this.token = null;
        this.role = null;
        this.userId = null; // Clear userId
        this.intendedRoute = null;
        this.csrfToken = null;

        // Clear localStorage
        localStorage.removeItem('token');
        localStorage.removeItem('userData');
        localStorage.removeItem('userRole');
        localStorage.removeItem('userId'); // Remove userId
        localStorage.removeItem('csrfToken');

        // Remove Authorization and CSRF headers
        delete axios.defaults.headers.common['Authorization'];
        delete axios.defaults.headers.common['X-CSRFToken'];

        // Show success toast
        toast.success('Logged out successfully');

        return true; // Indicate successful logout
      } catch (error) {
        console.error('Logout failed:', error);
        toast.error('Logout failed. Please try again.');
        return false; // Indicate failed logout
      }
    },
  },
});
