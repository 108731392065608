<!-- src/components/ModalComponent.vue -->
<template>
    <div v-if="isOpen" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
        <div class="bg-white rounded-lg p-6 max-w-lg w-full max-h-[95vh] overflow-y-auto">
            <img :src="modalImage" alt="Image of PM Tshering Tobgay" class="w-full h-auto mb-4 rounded max-h-72 object-cover align-top mx-auto">
            <p class="text-gray-700 mb-4 text-sm">
                Foreign Direct Investment (FDI) serves as a catalyst for various critical aspects of our nation's
                development, including the influx of foreign exchange reserves, infusion of investments into key
                sectors, job creation, and the revitalization of our economy. Recognizing the pivotal role of FDI in the
                economic growth and sustainability of a small nation like ours, the government commits to substantially
                increase FDI inflow from Nu.43 billion to Nu.500 billion in the next five years.
            </p>
            <button @click="closeModal" class="bg-bhutan-green text-white px-4 py-2 rounded hover:bg-bhutan-green-dark transition duration-300">
                Close
            </button>
        </div>
    </div>
</template>


<script setup lang="ts">
import { ref } from 'vue'
import modalImage from '@/assets/img/modal-image.png'

const isOpen = ref(false)

const closeModal = () => {
    isOpen.value = false
}

defineExpose({ isOpen, closeModal })
</script>
