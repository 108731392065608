<script setup lang="ts">
import { ref, onMounted } from 'vue'
import api from '@/services/api.js'
import { useModalStore } from '@/stores/modalStore'
import ModalComponent from '@/components/ModalComponent.vue'
import CarouselComponent from '@/components/CarouselComponent.vue'
import WhyBhutanComponent from '@/components/WhyBhutanComponent.vue'
import EDBSection from '@/components/EDBSection.vue'
import FDIRegulationsSection from '@/components/FDIRegulationsSection.vue'
import FDIStatisticsSection from '@/components/FDIStatisticsSection.vue'
import NewsAndEventsSection from '@/components/NewsAndEventsSection.vue'

const modalStore = useModalStore()
const showModal = ref(false)

const carouselItems = ref([])
const whyBhutanContent = ref({ title: '', paragraphs: [{ text: '' }] })
const whyBhutanCards = ref([])
const chairperson = ref({})
const otherMembers = ref([])
const chairpersonMessage = ref('')
const edbContent = ref({ title: '', content: '' })
const fdiRegulations = ref('')
const videoId = ref('')
const sectoralShareData = ref([])
const fdiInvestorsData = ref([])
const fdiProjectsData = ref({})

const fetchHomePageData = async () => {
  try {
    const response = await api.get('/webcontents/api/web-pages/home/')
    const data = response.data

    // Process Why Bhutan content
    const whyBhutanSection = data.contents.find(
      (content) => content.tags === 'why-bhutan' && content.content_type === 'paragraph'
    )
    if (whyBhutanSection) {
      whyBhutanContent.value = whyBhutanSection
    }

    // Process Why Bhutan cards
    const whyBhutanCardsSection = data.contents.find(
      (content) => content.tags === 'why-bhutan' && content.content_type === 'card'
    )
    if (whyBhutanCardsSection) {
      whyBhutanCards.value = whyBhutanCardsSection.cards.map((card) => ({
        id: card.id,
        title: card.card_title,
        excerpt: card.card_text,
        image: card.card_image
      }))
    }

    // Process EDB content
    const edbSection = data.contents.find((content) => content.tags === 'edb')
    if (edbSection) {
      edbContent.value = {
        title: edbSection.title,
        content: edbSection.paragraphs[0].text
      }
    }

    // Process Chairperson message
    const chairpersonMessageSection = data.contents.find(
      (content) => content.tags === 'message from pm'
    )
    if (chairpersonMessageSection) {
      chairpersonMessage.value = chairpersonMessageSection.paragraphs[0].text
    }

    // Process FDI Regulations
    const fdiRegulationsSection = data.contents.find(
      (content) => content.tags === 'fdi regulations'
    )
    if (fdiRegulationsSection) {
      fdiRegulations.value = fdiRegulationsSection.paragraphs[0].text
    }

    // Process YouTube link
    const youtubeSection = data.contents.find((content) => content.tags === 'youtube')
    if (youtubeSection) {
      videoId.value = youtubeSection.paragraphs[0].text
    }
  } catch (error) {
    console.error('Error fetching home page data:', error)
  }
}

const fetchCarouselItems = async () => {
  try {
    const response = await api.get('/webcontents/api/slider-images/')
    console.log('Carousel Items:', response.data)
    carouselItems.value = response.data.map((item: any) => ({
      id: item.id,
      image: item.image,
      alt: item.title,
      text: item.caption,
      route: '#'
    }))
  } catch (error) {
    console.error('Error fetching carousel items:', error)
  }
}

const fetchEDBTeam = async () => {
  try {
    const response = await api.get('/webcontents/api/edb-team/')
    const teamMembers = response.data.map((item: any) => ({
      name: item.name,
      position: `${item.title}, ${item.role}`,
      image: item.photo
    }))

    if (teamMembers.length > 0) {
      chairperson.value = teamMembers[0]
      otherMembers.value = teamMembers.slice(1)
    }
  } catch (error) {
    console.error('Error fetching EDB team members:', error)
  }
}

const fetchChartData = async () => {
  try {
    const response = await api.get('/webcontents/api/homepage-charts/')
    const chartsData = response.data

    chartsData.forEach((chart: any) => {
      if (chart.chart_title === 'Sectoral share of FDI') {
        sectoralShareData.value = chart.sectorial_share_charts.map((item: any) => ({
          label: item.sector_name,
          value: parseFloat(item.total_project_value),
          total_projects: item.project_counts
        }))
      } else if (chart.chart_title === 'FDI Investors (by Origin)') {
        fdiInvestorsData.value = chart.origin_charts.map((item: any) => ({
          label: item.origin_name,
          value: item.origin_count
        }))
      } else if (chart.chart_title === 'FDI Projects Distribution (by Dzongkhag)') {
        fdiProjectsData.value = chart.svg_map_charts.reduce((acc: any, item: any) => {
          acc[item.dzongkhag_name] = item.fdi_count
          return acc
        }, {})
      }
    })
  } catch (error) {
    console.error('Error fetching chart data:', error)
  }
}

const checkAndShowModal = () => {
  const lastShownDate = localStorage.getItem('lastModalShownDate')
  const today = new Date().toDateString()

  if (!lastShownDate || lastShownDate !== today) {
    showModal.value = true
    localStorage.setItem('lastModalShownDate', today)
  }
}

onMounted(() => {
  fetchHomePageData()
  fetchCarouselItems()
  fetchEDBTeam()
  fetchChartData()
  checkAndShowModal()
})
</script>

<template>
  <main class="min-h-screen">
    <ModalComponent ref="modalRef" />
    <!-- Carousel Section -->
    <CarouselComponent :items="carouselItems" />

    <!-- Why Bhutan Section -->
    <WhyBhutanComponent
      :heading="whyBhutanContent.title"
      :paragraph="whyBhutanContent.paragraphs[0].text"
      :investment-cards="whyBhutanCards"
      @show-more="handleShowMore"
    />

    <!-- EDB Section -->
    <EDBSection
      :chairperson="chairperson"
      :chairpersonMessage="chairpersonMessage"
      :other-members="otherMembers"
      :edbContent="edbContent"
    />

    <!-- FDI Regulations Section -->
    <FDIRegulationsSection :fdiRegulations="fdiRegulations" :videoId="videoId" />

    <!-- FDI Statistics Section -->
    <FDIStatisticsSection
      :sectoralShareData="sectoralShareData"
      :fdiInvestorsData="fdiInvestorsData"
      :fdiProjectsData="fdiProjectsData"
    />

    <!-- News and Events Section -->
    <NewsAndEventsSection />
  </main>
</template>
