<script setup lang="ts">
import { RouterView, useRoute } from 'vue-router'
import { computed } from 'vue'
import HeaderComponent from './components/Common/HeaderComponent.vue'
import NavBarComponent from './components/Common/NavBarComponent.vue'
import FooterComponent from './components/Common/FooterComponent.vue'
import ScrollToTopButton from './components/Buttons/ScrollToTopButton.vue'
import ChatComponent from '@/components/ChatComponent.vue'
import { useUserStore } from '@/stores/userStore'

const route = useRoute()
const userStore = useUserStore()
userStore.initializeStore()

const isDashboardRoute = computed(() => {
  const dashboardPrefixes = [
    '/dashboard/',
    '/invest-bhutan/',
    '/chief/',
    '/director-general/',
    '/dasho-secretary/',
    '/sector-agencies/',
    '/approved-fdi/',
    '/registered-fdi/',
    '/service-provider/',
    '/foreign-investor/',
    '/local-investor/',
    '/rma/'
  ]
  return dashboardPrefixes.some(prefix => route.path.startsWith(prefix))
})

const showChatIcon = computed(() => {
  return !isDashboardRoute.value && !route.path.includes('/dashboard/')
})
</script>

<template>
  <div>
    <template v-if="!isDashboardRoute">
      <HeaderComponent />
      <div class="absolute top-2 right-2 z-10">
      <div id="google_translate_element" style="max-height: 20px"></div>
    </div>
      <NavBarComponent />
    </template>

    <RouterView />

    <template v-if="!isDashboardRoute">
      <FooterComponent />
      <ScrollToTopButton />
    </template>

    <ChatComponent v-if="showChatIcon" />
  </div>
</template>

<style>
@import './assets/main.css';
</style>