<template>
    <section class="py-16 bg-white">
        <div class="max-w-6xl mx-auto px-4">
            <h2 class="text-3xl font-bold text-bhutan-green mb-8 animate-fadeIn">FDI at a Glance</h2>

            <div class="mb-20 animate-fadeInUp">
                <h3 class="text-xl font-semibold mb-4">Sectoral share of FDI</h3>
                <div ref="sectoralShareChartRef" class="w-full h-96"></div>
            </div>

            <div class="grid grid-cols-1 lg:grid-cols-12 gap-8 mb-10">
                <div class="col-span-1 lg:col-span-5 animate-fadeInRight">
                    <h3 class="text-xl font-semibold mb-4 text-bhutan-green">FDI Investors (by Origin)</h3>
                    <div ref="fdiInvestorsChartRef" class="w-full h-80"></div>
                </div>
                <div class="col-span-1 lg:col-span-7 animate-fadeInLeft">
                    <h3 class="text-xl font-semibold mb-4 text-bhutan-green">FDI Projects Distribution (by Dzongkhag)
                    </h3>
                    <div ref="bhutanMapRef" class="w-full h-96"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import ApexCharts from 'apexcharts'
import BhutanCharts from '@/assets/bhutan.svg'

const props = defineProps<{
    sectoralShareData: Array<{ label: string; value: number; total_projects: number }>;
    fdiInvestorsData: Array<{ label: string; value: number }>;
    fdiProjectsData: { [key: string]: number };
}>()

const sectoralShareChartRef = ref<HTMLElement | null>(null)
const fdiInvestorsChartRef = ref<HTMLElement | null>(null)
const bhutanMapRef = ref<HTMLElement | null>(null)

const sectoralShareChart = ref<ApexCharts | null>(null)
const fdiInvestorsChart = ref<ApexCharts | null>(null)

const renderSectoralShareChart = () => {
    if (sectoralShareChartRef.value && props.sectoralShareData.length > 0) {
        if (sectoralShareChart.value) {
            sectoralShareChart.value.destroy()
        }
        sectoralShareChart.value = new ApexCharts(sectoralShareChartRef.value, {
            chart: { type: 'bar', height: '100%', animations: { enabled: true } },
            plotOptions: { bar: { horizontal: true, borderRadius: 5, dataLabels: { position: 'top' } } },
            series: [{ data: props.sectoralShareData.map(item => item.value) }],
            xaxis: { categories: props.sectoralShareData.map(item => item.label), title: { text: 'In Millions' } },
            colors: ['#136d66'],
            dataLabels: { enabled: false, formatter: function (val: any) { return "Nu." + val } },
            tooltip: {
                theme: 'light',
                style: { fontSize: '10px' },
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    const data = props.sectoralShareData[dataPointIndex];
                    return `
                        <div class="tooltip-content p-3">
                            <span><strong>${data.label}</strong></span><br>
                            <span><strong>Total Project Value:</strong> Nu.${data.value} mil</span>
                            <br>
                            <span><strong>No. of FDI Project(s):</strong> ${data.total_projects}</span>
                        </div>
                    `;
                }
            }
        })
        sectoralShareChart.value.render()
    }
}


const renderFDIInvestorsChart = () => {
    if (fdiInvestorsChartRef.value && props.fdiInvestorsData.length > 0) {
        if (fdiInvestorsChart.value) {
            fdiInvestorsChart.value.destroy()
        }
        fdiInvestorsChart.value = new ApexCharts(fdiInvestorsChartRef.value, {
            chart: { type: 'pie', height: '100%', animations: { enabled: true } },
            series: props.fdiInvestorsData.map(item => item.value),
            labels: props.fdiInvestorsData.map(item => item.label),
            colors: ['#136d66', '#1a8f85', '#20b2a6','#26e4b7', // Modified Teal-Green
                '#2cf6c0', // Modified Aqua-Green
                '#32ffca'],
            responsive: [{ breakpoint: 480, options: { chart: { width: 200 }, legend: { position: 'bottom' } } }]
        })
        fdiInvestorsChart.value.render()
    }
}

// Ensure to attach the events and also detach the tooltip on mouse leave
function renderBhutanMap() {
    if (bhutanMapRef.value && Object.keys(props.fdiProjectsData).length > 0) {
        fetch(BhutanCharts)
            .then(response => response.text())
            .then(svgContent => {
                bhutanMapRef.value!.innerHTML = svgContent;
                const maxFDICount = Math.max(...Object.values(props.fdiProjectsData));
                bhutanMapRef.value!.querySelectorAll('path').forEach(path => {
                    const dzongkhag = path.getAttribute('title');
                    if (dzongkhag && dzongkhag in props.fdiProjectsData) {
                        const fdiCount = props.fdiProjectsData[dzongkhag];
                        path.setAttribute('data-fdi-count', fdiCount.toString());
                        const fillColor = getFillColor(fdiCount, maxFDICount);
                        path.setAttribute('fill', fillColor);
                        path.addEventListener('mouseover', showTooltip);
                        path.addEventListener('mousemove', showTooltip); // Ensure tooltip follows the mouse
                        path.addEventListener('mouseout', hideTooltip);
                    }
                });
            })
            .catch(error => {
                console.error('Error fetching Bhutan map:', error);
            });
    }
}

function getFillColor(count: number, max: number): string {
    const intensity = count / max
    const r = Math.round(224 + (19 - 224) * intensity)
    const g = Math.round(224 + (109 - 224) * intensity)
    const b = Math.round(224 + (102 - 224) * intensity)
    return `rgb(${r}, ${g}, ${b})`
}

let activeTooltip: HTMLElement | null = null;

function showTooltip(event: MouseEvent) {
    hideTooltip(); // Remove any existing tooltip before showing a new one

    const target = event.target as SVGPathElement;
    const dzongkhag = target.getAttribute('title');
    const fdiCount = target.getAttribute('data-fdi-count');

    if (dzongkhag && fdiCount) {
        const tooltip = document.createElement('div');
        tooltip.className = 'absolute bg-white text-gray-800 p-2 rounded shadow-md text-sm';
        tooltip.style.position = 'fixed'; // Position it as fixed to ensure it stays on screen
        tooltip.style.left = `${event.clientX + 10}px`; // Offset for better visibility
        tooltip.style.top = `${event.clientY + 10}px`;
        tooltip.innerHTML = `${dzongkhag}: ${fdiCount} FDI projects`;
        document.body.appendChild(tooltip);

        activeTooltip = tooltip;
    }
}

function hideTooltip() {
    if (activeTooltip) {
        activeTooltip.remove();
        activeTooltip = null;
    }
}

watch(() => props.sectoralShareData, renderSectoralShareChart, { immediate: true, deep: true })
watch(() => props.fdiInvestorsData, renderFDIInvestorsChart, { immediate: true, deep: true })
watch(() => props.fdiProjectsData, renderBhutanMap, { immediate: true, deep: true })

onMounted(() => {
    renderSectoralShareChart()
    renderFDIInvestorsChart()
    renderBhutanMap()
})
</script>

<style scoped>
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(20px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.animate-fadeIn {
    animation: fadeIn 0.5s ease-out forwards;
}

.animate-fadeInLeft {
    animation: fadeInLeft 0.5s ease-out forwards;
}

.animate-fadeInRight {
    animation: fadeInRight 0.5s ease-out forwards;
}

.animate-fadeInUp {
    animation: fadeInUp 0.5s ease-out forwards;
}

#bhutan-map path {
    stroke: #ffffff;
    stroke-width: 1;
    transition: fill 0.3s;
}

#bhutan-map path:hover {
    filter: brightness(90%);
}

svg#SvgjsSvg1001 path:hover {
    cursor: pointer;
    outline: 2px solid black;
}
</style>
