<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
import { ArrowUp } from 'lucide-vue-next'

const showButton = ref(false)

const checkScroll = () => {
  const footer = document.querySelector('footer')
  if (footer) {
    const footerRect = footer.getBoundingClientRect()
    showButton.value = footerRect.top <= window.innerHeight
  }
}

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

onMounted(() => {
  window.addEventListener('scroll', checkScroll)
  checkScroll() // Initial check
})

onUnmounted(() => {
  window.removeEventListener('scroll', checkScroll)
})
</script>

<template>
  <button
    v-show="showButton"
    @click="scrollToTop"
    class="fixed bottom-4 right-4 bg-bhutan-green text-white p-2 rounded-full shadow-lg hover:bg-bhutan-green-light transition-colors duration-300"
    aria-label="Scroll to top"
  >
    <ArrowUp size="24" />
  </button>
</template>