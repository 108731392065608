<template>
    <div class="relative h-[70vh] overflow-hidden">
        <TransitionGroup name="fade">
            <div v-for="(item, index) in items" :key="item.id" v-show="index === currentSlide" class="absolute inset-0">
                <img :src="item.image" :alt="item.alt" class="w-full h-full object-cover" />
                <div
                    class="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-center text-white p-4">
                    <h2 class="text-3xl md:text-4xl font-bold mb-4 animate-fadeInDown">{{ item.alt }}</h2>
                    <p class="text-lg md:text-xl mb-6 animate-fadeInUp">{{ item.text }}</p>
                    <router-link :to="item.route">
                        <!-- <button
                            class="bg-bhutan-green hover:bg-bhutan-green-light text-white font-bold py-2 px-4 md:py-3 md:px-6 rounded-full transition duration-300 transform hover:scale-105">
                            Read More
                        </button> -->
                    </router-link>
                </div>
            </div>
        </TransitionGroup>
        <button @click="prevSlide"
            class="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 md:p-3 rounded-full hover:bg-opacity-75 transition-all">
            <ChevronLeft class="text-bhutan-green" />
        </button>
        <button @click="nextSlide"
            class="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 md:p-3 rounded-full hover:bg-opacity-75 transition-all">
            <ChevronRight class="text-bhutan-green" />
        </button>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
import { ChevronLeft, ChevronRight } from 'lucide-vue-next'

const props = defineProps<{
    items: Array<{
        id: number;
        image: string;
        alt: string;
        text: string;
        route: string;
    }>;
}>();

const currentSlide = ref(0);
let intervalId: number | null = null;

const nextSlide = () => {
    currentSlide.value = (currentSlide.value + 1) % props.items.length;
};

const prevSlide = () => {
    currentSlide.value = (currentSlide.value - 1 + props.items.length) % props.items.length;
};

onMounted(() => {
    intervalId = setInterval(nextSlide, 5000);
});

onUnmounted(() => {
    if (intervalId) clearInterval(intervalId);
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -20%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 20%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.animate-fadeInDown {
    animation: fadeInDown 0.5s ease-out;
}

.animate-fadeInUp {
    animation: fadeInUp 0.5s ease-out 0.2s both;
}
</style>