import axios from 'axios';

const baseURL = window.location.hostname === 'localhost' 
    ? 'http://localhost:8080/' 
    : 'https://investbhutan.gov.bt/be/';

const api = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
    },
});

export default api;
export { baseURL };
