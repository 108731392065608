<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
import { ChevronLeft, ChevronRight, Newspaper } from 'lucide-vue-next'
import sectorBg from '../assets/img/aaa.jpg'
import userImg from '@/assets/img/user.png'
import api from '@/services/api'

interface NewsItem {
  id: number
  title: string
  content: string
  slug: string
  image: string
  published_date: string
}

const newsItems = ref<NewsItem[]>([])
const currentIndex = ref(0)
const intervalId = ref<number | null>(null)

const nextNews = () => {
  currentIndex.value = (currentIndex.value + 1) % newsItems.value.length
}

const prevNews = () => {
  currentIndex.value = (currentIndex.value - 1 + newsItems.value.length) % newsItems.value.length
}

const fetchNewsAndEvents = async () => {
  try {
    const response = await api.get('/webcontents/api/news-and-events/')
    newsItems.value = response.data
  } catch (error) {
    console.error('Error fetching news and events:', error)
  }
}

onMounted(() => {
  fetchNewsAndEvents()
  intervalId.value = setInterval(nextNews, 5000) as unknown as number
})

onUnmounted(() => {
  if (intervalId.value) clearInterval(intervalId.value)
})
</script>

<template>
  <section
    class="relative bg-cover bg-center py-16"
    :style="{ backgroundImage: `url(${sectorBg})` }"
  >
    <div class="absolute inset-0 bg-black opacity-50"></div>
    <div class="container mx-auto px-4 relative z-10">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <!-- Left Grid: Title and View More button -->
        <div class="text-white">
          <h2 class="text-4xl font-bold mb-4">NEWS AND EVENTS</h2>
          <a
            href="/news"
            class="inline-block bg-bhutan-green hover:bg-bhutan-green-light text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            View More
          </a>
        </div>

        <!-- Right Grid: News Cards -->
        <div v-if="newsItems.length > 0" class="relative bg-white rounded-lg shadow-lg p-6">
          <Newspaper class="text-bhutan-green mb-4" size="32" />
          <div class="mb-4">
            <h3 class="text-xl font-semibold mb-2">{{ newsItems[currentIndex].title }}</h3>
            <div
              class="text-gray-600 mb-4"
              v-html="newsItems[currentIndex].content.substring(0, 300) + '...'"
            ></div>
            <router-link
              :to="`/news-and-events/${newsItems[currentIndex].slug}`"
              class="text-bhutan-green hover:underline"
              >Read More</router-link
            >
          </div>
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <img :src="userImg" alt="Admin" class="w-10 h-10 rounded-full mr-3" />
              <div>
                <p class="text-sm font-medium">Admin</p>
                <p class="text-xs text-gray-500">
                  {{ new Date(newsItems[currentIndex].published_date).toLocaleDateString() }}
                </p>
              </div>
            </div>
          </div>
          <div class="absolute top-1/2 -translate-y-1/2 left-0 -ml-4">
            <button
              @click="prevNews"
              class="bg-white rounded-full p-2 shadow-md hover:bg-gray-100 transition duration-300"
            >
              <ChevronLeft class="text-bhutan-green" size="24" />
            </button>
          </div>
          <div class="absolute top-1/2 -translate-y-1/2 right-0 -mr-4">
            <button
              @click="nextNews"
              class="bg-white rounded-full p-2 shadow-md hover:bg-gray-100 transition duration-300"
            >
              <ChevronRight class="text-bhutan-green" size="24" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
