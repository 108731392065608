<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/userStore'
import { baseURL } from '@/services/api.js'

const scrollText = ref(
  'If you want to get registered than please click on the Register Button. Thank you'
)
const isMobile = ref(false)

const router = useRouter()
const userStore = useUserStore()

const isLoggedIn = computed(() => !!userStore.token)

// Computed property to determine if the user is an admin
const isAdminUser = computed(() => {
  const adminTypes = ['secretary', 'directorgeneral', 'chief', 'investbhutan', 'webmaster', 'sectoragency'];
  return adminTypes.includes(userStore.user?.user_type || '');
});

// Computed property to determine the dashboard link
const dashboardLink = computed(() => {
  if (isAdminUser.value) {
    return `${baseURL}admin/`;
  }

  const userType = userStore.user?.user_type;
  switch (userType) {
    case 'localinvestor':
      return '/local-investor/home';
    case 'foreigninvestor':
      return '/foreign-investor/home';
    case 'serviceprovider':
      return '/service-provider/home';
    case 'registeredfdi':
      return '/registered-fdi/home';
    case 'approvedfdi':
      return '/approved-fdi/home';
    case 'rma':
      return '/rma/home';
    default:
      return '/';
  }
});

// Function to handle the dashboard button click
const handleDashboardClick = () => {
  if (isAdminUser.value) {
    window.location.href = dashboardLink.value;
  } else {
    router.push(dashboardLink.value);
  }
};

const checkMobile = () => {
  isMobile.value = window.innerWidth < 768
}

onMounted(() => {
  checkMobile()
  window.addEventListener('resize', checkMobile)
})

onUnmounted(() => {
  window.removeEventListener('resize', checkMobile)
})

const logout = () => {
  userStore.logout()
  router.push('/')
}

const languages = ref<{ code: string; name: string }[]>([])
const selectedLanguage = ref('en')

// Watch for changes in the languages array
watch(languages, (newLanguages) => {
  if (newLanguages.length > 0 && !selectedLanguage.value) {
    selectedLanguage.value = newLanguages[0].code
  }
})

defineExpose({})
</script>

<template>
  <header class="bg-blue-90 shadow-md">
    <!-- Scrolling text -->
    <div class="overflow-hidden py-2 w-full bg-gray-100">
      <div class="whitespace-nowrap animate-scrolling-text text-bhutan-green">
        {{ scrollText }}
      </div>
    </div>

    <div class="container mx-auto px-4 relative">
      <!-- Main header content -->
      <div class="flex flex-col md:flex-row justify-between items-center py-4">
        <!-- Left: Logo and Title -->
        <div class="flex items-center mb-4 md:mb-0">
          <router-link to="/">
            <img src="@/assets/img/rgob.png" alt="Logo" class="h-34 md:h-34 w-auto mr-4" />
          </router-link>
        </div>

        <!-- Center: Second Logo -->
        <div class="md:absolute md:inset-0 flex justify-center items-center pointer-events-none mb-4 md:mb-0">
          <router-link to="/">
            <img src="@/assets/img/logo.png" alt="Logo2" class="h-20 md:h-28 w-auto" />
          </router-link>
        </div>

        <!-- Right: Login-dependent Buttons -->
        <div class="space-x-4">
          <template v-if="isLoggedIn">
            <button @click="handleDashboardClick"
              class="bg-bhutan-green hover:bg-bhutan-green-light text-white font-bold py-2 px-4 rounded transition duration-300">
              {{ isMobile ? 'Dash' : 'Dashboard' }}
            </button>
            <button @click="logout"
              class="bg-bhutan-green hover:bg-bhutan-green-light text-white font-bold py-2 px-4 rounded transition duration-300">
              {{ isMobile ? 'Out' : 'Logout' }}
            </button>
          </template>
          <template v-else>
            <router-link to="/login">
              <button
                class="bg-bhutan-green hover:bg-bhutan-green-light text-white font-bold py-2 px-4 rounded transition duration-300">
                {{ iMobile ? 'Log' : 'Login' }}
              </button>
            </router-link>
            <router-link to="/register">
              <button
                class="bg-bhutan-green hover:bg-bhutan-green-light text-white font-bold py-2 px-4 rounded transition duration-300">
                {{ isMobile ? 'Reg' : 'Register' }}
              </button>
            </router-link>
          </template>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>
@keyframes scrolling-text {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.animate-scrolling-text {
  display: inline-block;
  animation: scrolling-text 20s linear infinite;
}
</style>