import { defineStore } from 'pinia'

export const useModalStore = defineStore('modal', {
  state: () => ({
    lastShownDate: null as string | null,
  }),
  actions: {
    setLastShownDate() {
      const now = new Date().toISOString()
      this.lastShownDate = now
      localStorage.setItem('lastModalShownDate', now)
    },
    getLastShownDate(): string | null {
      if (!this.lastShownDate) {
        this.lastShownDate = localStorage.getItem('lastModalShownDate')
      }
      return this.lastShownDate
    },
    shouldShowModal(): boolean {
      const lastShown = this.getLastShownDate()
      if (!lastShown) return true

      const lastShownDate = new Date(lastShown)
      const now = new Date()
      const oneDayInMs = 24 * 60 * 60 * 1000
      return now.getTime() - lastShownDate.getTime() >= oneDayInMs
    },
  },
})