import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/userStore'
import HomeView from '@/views/HomeView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    // Site Routes
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/sample',
      name: 'sample',
      component: () => import('@/views/ITESView.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/LoginView.vue')
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/auth/RegisterView.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/views/AboutUsView.vue')
    },
    {
      path: '/why-bhutan',
      name: 'why-bhutan',
      component: () => import('@/views/WhyBhutanView.vue')
    },
    {
      path: '/investment-opportunities',
      name: 'investment-opportunities',
      component: () => import('@/views/InvestOpportunityView.vue')
    },
    {
      path: '/projects',
      name: 'projects',
      component: () => import('@/views/ProjectListsView.vue')
    },
    {
      path: '/project/:id',
      name: 'project-details',
      component: () => import('@/views/ProjectDetailsView.vue')
    },
    {
      path: '/fdi-regulation',
      name: 'fdi-regulation',
      component: () => import('@/views/FDIRegulationView.vue')
    },
    {
      path: '/resources',
      name: 'resources',
      component: () => import('@/views/ResourcesView.vue')
    },
    {
      path: '/service-provider',
      name: 'service-provider',
      component: () => import('@/views/ServiceProviderView.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('@/views/ContactUsView.vue')
    },
    {
      path: '/faqs',
      name: '/faqs',
      component: () => import('@/views/FAQView.vue')
    },
    {
      path: '/news-and-events/:slug',
      name: 'NewsAndEventsDetail',
      component: () => import('@/views/NewsAndEventsDetailsView.vue')
    },
    {
      path: '/sector-profile/:id',
      name: 'sector-profile',
      component: () => import('@/views/SectorProfileView.vue')
    },
    {
      path: '/news',
      name: 'News',
      component: () => import('@/views/NewsAndEventsView.vue')
    },
    {
      path: '/news/:slug',
      name: 'Article',
      component: () => import('@/views/NewsAndEventsView.vue')
    },

    // Approved FDI routes
    {
      path: '/approved-fdi',
      name: 'approved-fdi',
      children: [
        {
          path: 'home',
          name: 'approved-fdi-home',
          component: () => import('@/views/dashboard/common/AdminDashboardView.vue')
        },
        {
          path: 'business-guest-visa',
          name: 'business-guest-visa',
          component: () =>
            import('@/views/dashboard/approvedFdiDashboard/BusinessGuestVisaApplicationView.vue')
        },
        {
          path: 'investor-card',
          name: 'approved-fdi-investor-card',
          component: () => import('@/views/dashboard/approvedFdiDashboard/InvestorCardView.vue')
        },
        {
          path: 'renew-investor-card',
          name: 'approved-fdi-renew-investor-card',
          component: () =>
            import('@/views/dashboard/approvedFdiDashboard/RenewInvestorCardView.vue')
        },
        {
          path: 'equity-subscription',
          name: 'approved-fdi-equity-subscription',
          component: () =>
            import('@/views/dashboard/approvedFdiDashboard/EquitySubscriptionView.vue')
        },
        {
          path: 'feedback',
          name: 'approved-fdi-feedback',
          component: () => import('@/views/dashboard/approvedFdiDashboard/FeedbackView.vue')
        },
        {
          path: 'others',
          name: 'approved-fdi-others',
          component: () => import('@/views/dashboard/approvedFdiDashboard/OthersApplicationView.vue')
        },
        {
          path: 'profile',
          name: 'approved-fdi-user-profile',
          component: () => import('@/views/dashboard/Common/UserProfileView.vue')
        },
       
      ]
    },

    // Registered FDI routes
    {
      path: '/registered-fdi',
      name: 'registered-fdi',
      children: [
        {
          path: 'home',
          name: 'registered-fdi-dashboard',
          component: () => import('@/views/dashboard/common/AdminDashboardView.vue')
        },
        {
          path: 'business-guest-visa',
          name: 'registered-business-guest-visa',
          component: () => import('@/views/dashboard/registered-fdi/RegisteredFdiVisaApplication.vue')
        },
        {
          path: 'fdi-certificate-extension',
          name: 'fdi-certificate-extension',
          component: () =>
            import('@/views/dashboard/registered-fdi/RFDIrcxtension.vue')
        },
        {
          path: 'equity-subscription',
          name: 'registered-fdi-equity-subscription',
          component: () =>
            import('@/views/dashboard/registered-fdi/EquitySubscriptionView.vue')
        },
        {
          path: 'feedbacks',
          name: 'registered-fdi-feedbacks',
          component: () => import('@/views/dashboard/registered-fdi/FeedbacksView.vue')
        },
        {
          path: 'others',
          name: 'registered-fdi-others',
          component: () => import('@/views/dashboard/registered-fdi/OthersApplicationView.vue')
        },
        {
          path: 'profile',
          name: 'registered-fdi-user-profile',
          component: () => import('@/views/dashboard/Common/UserProfileView.vue')
        },
      ]
    },

    // Service Provider routes
    {
      path: '/service-provider',
      name: 'admin-service-provider',
      children: [
        {
          path: 'home',
          name: 'service-provider-dashboard',
          component: () => import('@/views/dashboard/common/AdminDashboardView.vue')
        },
        {
          path: 'apply',
          name: 'service-provider-apply',
          component: () => import('@/views/dashboard/service-provider/ApplyView.vue')
        },
        {
          path: 'feedbacks',
          name: 'service-provider-feedbacks',
          component: () => import('@/views/dashboard/service-provider/FeedbacksView.vue')
        },
        {
          path: 'profile',
          name: 'service-provider-user-profile',
          component: () => import('@/views/dashboard/Common/UserProfileView.vue')
        },
      ]
    },

    // Foreign Investor routes
    {
      path: '/foreign-investor',
      name: 'foreign-investor',
      children: [
        {
          path: 'home',
          name: 'foreign-investor-dashboard',
          component: () => import('@/views/dashboard/common/AdminDashboardView.vue')
        },
        {
          path: 'apply',
          name: 'foreign-investor-apply',
          component: () => import('@/views/dashboard/foreign-investor/ApplyView.vue')
        },
        {
          path: 'feedbacks',
          name: 'foreign-investor-feedbacks',
          component: () => import('@/views/dashboard/foreign-investor/FeedbacksView.vue')
        },
        {
          path: 'profile',
          name: 'foreign-investor-user-profile',
          component: () => import('@/views/dashboard/Common/UserProfileView.vue')
        },
      ]
    },

    // Local Investor routes
    {
      path: '/local-investor',
      name: 'local-investor',
      children: [
        {
          path: 'home',
          name: 'local-investor-dashboard',
          component: () => import('@/views/dashboard/common/AdminDashboardView.vue')
        },
        {
          path: 'apply',
          name: 'local-investor-apply',
          component: () => import('@/views/dashboard/local-investor/ApplyView.vue')
        },
        {
          path: 'feedbacks',
          name: 'local-investor-feedbacks',
          component: () => import('@/views/dashboard/local-investor/FeedbacksView.vue')
        },
        {
          path: 'profile',
          name: 'local-investor-user-profile',
          component: () => import('@/views/dashboard/Common/UserProfileView.vue')
        },
      ]
    },

    // RMA routes
    {
      path: '/rma',
      name: 'rma',
      children: [
        {
          path: 'home',
          name: 'rma-dashboard',
          component: () => import('@/views/dashboard/rmaDashboard/RmaDashboardView.vue')
        }
      ]
    },

    // Catch-all route for 404 Not Found
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('@/views/NotFoundView.vue')
    }
  ]
})

function isAuthenticated() {
  const userStore = useUserStore()
  return !!userStore.token
}

const staticPublicPages = [
  '/',
  '/login',
  '/register',
  '/about',
  '/why-bhutan',
  '/investment-opportunities',
  '/fdi-regulation',
  '/resources',
  '/service-provider',
  '/contact',
  '/projects',
  '/faqs',
  '/news',
]

const dynamicPublicPages = [
  '/sector-profile/:id',
  '/news-and-events/:slug',
  '/news/:slug',
]

router.beforeEach((to, from, next) => {
  const userStore = useUserStore()

  const isStaticPublicPage = staticPublicPages.includes(to.path)
  const isDynamicPublicPage = dynamicPublicPages.some(pattern => {
    const regexPattern = new RegExp('^' + pattern.replace(/:\w+/g, '[^/]+') + '$')
    return regexPattern.test(to.path)
  })
  const isProjectPage = to.path.startsWith('/project/')

  const authRequired = !(isStaticPublicPage || isDynamicPublicPage || isProjectPage)
  const loggedIn = isAuthenticated()

  if (authRequired && !loggedIn) {
    userStore.setIntendedRoute(to.fullPath)
    return next('/login')
  }

  if ((to.path === '/login' || to.path === '/register') && loggedIn) {
    return next('/dashboard/home')
  }

  next()
})

export default router
