// src/components/FDIRegulationsSection.vue
<template>
    <section class="py-16 relative bg-cover bg-center" :style="{ backgroundImage: `url(${sectorBg})` }">
        <div class="absolute inset-0 bg-black opacity-50"></div>
        <div class="max-w-6xl mx-auto px-4 relative z-10 flex flex-col md:flex-row items-center justify-between">
            <div class="w-full md:w-1/2 bg-white p-8 rounded-lg shadow-lg animate-fadeInLeft">
                <h2 class="text-3xl font-bold text-bhutan-green mb-4">Investment Promotion Video</h2>
                <p class="text-gray-700 mb-4" v-html="fdiRegulations"></p>
            </div>
            <div class="w-full md:w-1/3 mt-8 md:mt-0 flex justify-center items-center animate-fadeInRight">
                <button @click="openModal"
                    class="text-white hover:text-bhutan-green-light transition-colors transform hover:scale-110">
                    <PlayCircle size="64" />
                </button>
            </div>
        </div>

        <!-- Video Modal -->
        <Transition name="fade">
            <div v-if="showModal"
                class="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
                <div class="bg-white rounded-lg p-4 w-full max-w-3xl animate-zoomIn">
                    <div class="flex justify-end mb-2">
                        <button @click="closeModal" class="text-gray-500 hover:text-gray-700">
                            <XCircle />
                        </button>
                    </div>
                    <div class="relative pb-9/16">
                        <iframe class="absolute top-0 left-0 w-full h-full" :src="videoEmbedUrl" frameborder="0"
                            allow="autoplay; encrypted-media" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </Transition>
    </section>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { XCircle, PlayCircle } from 'lucide-vue-next'
import sectorBg from '../assets/img/sector_bg.jpg'

const showModal = ref(false)

const props = defineProps<{
    fdiRegulations: string;
    videoId: string;
}>()

const openModal = () => {
    showModal.value = true
}

const closeModal = () => {
    showModal.value = false
}

const videoEmbedUrl = computed(() => {
    const url = new URL(props.videoId);
    const videoId = url.searchParams.get('v');
    return `https://www.youtube.com/embed/${videoId}`;
})
</script>

<style scoped>
.pb-9\/16 {
    padding-bottom: 56.25%;
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(20px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

.animate-fadeInLeft {
    animation: fadeInLeft 0.5s ease-out forwards;
}

.animate-fadeInRight {
    animation: fadeInRight 0.5s ease-out forwards;
}

.animate-zoomIn {
    animation: zoomIn 0.3s ease-out forwards;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
