<template>
  <section class="py-16 px-4 bg-white overflow-hidden">
    <div class="max-w-7xl mx-auto">
      <h2 class="text-4xl font-bold text-bhutan-green mb-6 animate-fadeIn">{{ edbContent.title }}</h2>
      <div class="text-gray-800 mb-6 animate-fadeIn animation-delay-200" v-html="displayContent"></div>
      <button class="bg-bhutan-green text-white px-6 py-2 rounded-full hover:bg-bhutan-green-light transition-all duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-bhutan-green focus:ring-opacity-50"
        @click="toggleReadMore">
        {{ readMore ? 'Read less' : 'Read more' }}
      </button>

      <h3 class="text-3xl font-bold mt-12 mb-6 animate-fadeIn animation-delay-400">Meet the EDB Team</h3>

      <div class="grid grid-cols-1 md:grid-cols-12 gap-8 mb-8">
        <!-- Image column - takes 3/12 of the width on medium screens and above -->
        <div class="md:col-span-3 animate-fadeIn animation-delay-500 group">
          <div
            class="relative overflow-hidden rounded-lg shadow-lg transition-all duration-300 transform group-hover:scale-105">
            <img :src="chairperson.image" :alt="chairperson.name"
              class="w-full h-auto object-cover transition-transform duration-300 group-hover:scale-110">
            <div
              class="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-0 group-hover:opacity-70 transition-opacity duration-300">
            </div>
            <div
              class="absolute bottom-0 left-0 p-4 text-white transform translate-y-full group-hover:translate-y-0 transition-transform duration-300">
              <h4 class="text-lg font-semibold">{{ chairperson.name }}</h4>
              <p class="text-xs">{{ chairperson.position }}</p>
            </div>
          </div>
        </div>

        <!-- Text column - takes 9/12 of the width on medium screens and above -->
        <div class="md:col-span-9 animate-fadeIn animation-delay-600">
          <h4 class="text-2xl font-semibold mb-4">Message from the Chairperson</h4>
          <p class="text-gray-800 typing-effect" ref="messageText">
            {{ chairpersonMessage }}
          </p>
        </div>
      </div>

      <transition-group name="staggered-fade" tag="div"
        class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mt-12" @before-enter="beforeEnter"
        @enter="enter" @leave="leave">
        <div v-for="(member, index) in displayedMembers" :key="member.name" :data-index="index" class="group">
          <div
            class="relative overflow-hidden rounded-lg shadow-md transition-all duration-300 transform group-hover:scale-105 group-hover:shadow-xl">
            <img :src="member.image" :alt="member.name"
              class="w-full h-64 object-cover transition-transform duration-300 group-hover:scale-110">
            <div
              class="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-0 group-hover:opacity-70 transition-opacity duration-300">
            </div>
            <div
              class="absolute bottom-0 left-0 p-4 text-white transform translate-y-full group-hover:translate-y-0 transition-transform duration-300">
              <h4 class="text-lg font-semibold">{{ member.name }}</h4>
              <p class="text-sm">{{ member.position }}</p>
            </div>
          </div>
        </div>
      </transition-group>

      <div class="text-center mt-12">
        <button @click="toggleMembers"
          class="bg-bhutan-green text-white px-6 py-2 rounded-full hover:bg-bhutan-green-light transition-all duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-bhutan-green focus:ring-opacity-50">
          {{ showAllMembers ? 'Hide Members' : 'Show All Members' }}
        </button>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'

interface TeamMember {
  name: string;
  position: string;
  image: string;
}

const props = defineProps<{
  chairperson: TeamMember;
  chairpersonMessage: string;
  otherMembers: TeamMember[];
  edbContent: { title: string; content: string };
}>();

const readMore = ref(false);
const showAllMembers = ref(false);

const displayContent = computed(() => {
  if (readMore.value) {
    return props.edbContent.content;
  } else {
    const truncatedContent = props.edbContent.content.substring(0, 319) + '...';
    return truncatedContent;
  }
});

const toggleReadMore = (event: Event) => {
  event.preventDefault();
  readMore.value = !readMore.value;
};

const displayedMembers = computed(() => {
  return showAllMembers.value ? props.otherMembers : [];
});
const toggleMembers = () => {
  showAllMembers.value = !showAllMembers.value;
};


</script>

<style scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes typing {
  from {
    width: 0
  }

  to {
    width: 100%
  }
}

.animate-fadeIn {
  animation: fadeIn 0.5s ease-out forwards;
}

.animate-typing {
  overflow: hidden;
  border-right: .15em solid orange;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: .15em;
  animation:
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

.animation-delay-200 {
  animation-delay: 0.2s;
}

.animation-delay-300 {
  animation-delay: 0.3s;
}

.animation-delay-400 {
  animation-delay: 0.4s;
}

.animation-delay-500 {
  animation-delay: 0.5s;
}

.animation-delay-600 {
  animation-delay: 0.6s;
}

.staggered-fade-move,
.staggered-fade-enter-active,
.staggered-fade-leave-active {
  transition: all 0.5s ease;
}

.staggered-fade-enter-from,
.staggered-fade-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.staggered-fade-leave-active {
  position: absolute;
}

.text-lg.mb-6.animate-fadeIn.animation-delay-200 h1,
.text-lg.mb-6.animate-fadeIn.animation-delay-200 h2,
.text-lg.mb-6.animate-fadeIn.animation-delay-200 h3,
.text-lg.mb-6.animate-fadeIn.animation-delay-200 p,
.text-lg.mb-6.animate-fadeIn.animation-delay-200 ul,
.text-lg.mb-6.animate-fadeIn.animation-delay-200 li {
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-weight: normal;
  color: inherit;
}

.text-lg.mb-6.animate-fadeIn.animation-delay-200 h1 {
  font-size: 2em;
  font-weight: bold;
}

.text-lg.mb-6.animate-fadeIn.animation-delay-200 h2 {
  font-size: 1.75em;
  font-weight: bold;
}

.text-lg.mb-6.animate-fadeIn.animation-delay-200 h3 {
  font-size: 1.5em;
  font-weight: bold;
}

.text-lg.mb-6.animate-fadeIn.animation-delay-200 p {
  margin-bottom: 1em;
}

.text-lg.mb-6.animate-fadeIn.animation-delay-200 ul {
  list-style-type: disc;
  margin-left: 1.5em;
}

.text-lg.mb-6.animate-fadeIn.animation-delay-200 li {
  margin-bottom: 0.5em;
}
</style>
